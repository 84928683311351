<template>
  <div class="flex gap-4 mb-6 justify-between items-center relative">
    <slot />
    <BaseButton
      look="ghost"
      v-bind="attrs"
      class="hidden lg:inline-grid"
    >
      <span>{{ buttonText }}</span><ArrowRightIcon class="size-5" />
    </BaseButton>
    <ArrowRightIcon
      class="lg:hidden size-8"
      aria-hidden="true"
    />
    <component
      :is="linkElement"
      v-bind="attrs"
      class="lg:hidden link-unstyled absolute inset-0 focus:ring-1"
      :aria-label="buttonText"
    />
  </div>
</template>

<script lang="ts" setup>
import { ArrowRightIcon } from '@heroicons/vue/24/outline'
import BaseButton from '~/components/BaseButton.vue'

defineOptions({
  inheritAttrs: false
})

defineProps({
  buttonText: {
    type: String,
    required: false,
    default: 'Näytä lisää'
  }
})

const attrs = useAttrs()

const linkElement = computed(() => attrs.href ? 'a' : attrs.to ? resolveComponent('nuxt-link') : 'div')
</script>
