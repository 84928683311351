<template>
  <div v-if="!error || blogs?.length && blogs.length > 0">
    <section class="py-6">
      <div class="container">
        <HeadingLink to="/uutiset-ja-artikkelit" button-text="Katso kaikki">
          <h2 class="beta">
            Uutiset ja artikkelit
          </h2>
        </HeadingLink>
      </div>

      <div class="lg:container">
        <div class="flex lg:grid lg:grid-cols-3 gap-6 overflow-x-auto lg:overflow-visible px-4 md:px-8 lg:px-0">
          <div
            v-for="blog in blogs"
            :key="blog.blogiartikkelinSlug"
            class="flex-shrink-0 mb-2 w-11/12 max-w-[300px] lg:max-w-none lg:w-auto flex flex-col"
          >
            <NuxtLink :to="`/uutiset-ja-artikkelit/${blog.blogiartikkelinSlug}`" class="text-default no-underline">
              <img
                v-if="blog.url"
                :src="`${blog.url}?aspect_ratio=3:2&crop_gravity=northwest`"
                class="w-full aspect-[3/2]"
                :alt="blog.altText || 'Blogi kuva'"
              >
              <div v-else aria-hidden="true" class="aspect-[3/2] object-cover bg-gray-100 text-xl font-semibold flex items-center justify-center">
                <PhotoIcon class="text-muted size-12" />
              </div>
            </NuxtLink>
            <div class="prose shadow-md px-4 lg:px-6 py-4 flex flex-col grow">
              <p class="text-sm text-secondary mb-2">
                {{ formatDate(blog.blogiartikkelinJulkaisupaiva) }}
              </p>
              <h3 class="mt-0">
                {{ blog.blogiartikkelinOtsikko }}
              </h3>
              <p>
                {{ blog.blogiartikkelinIngressi }}
              </p>
              <div class="mt-auto">
                <NuxtLink
                  :to="`/uutiset-ja-artikkelit/${blog.blogiartikkelinSlug}`"
                  class="inline-flex gap-1.5 items-center mt-4"
                >
                  <span>Lue lisää</span>
                  <ChevronRightIcon class="size-3" />
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ChevronRightIcon, PhotoIcon } from '@heroicons/vue/24/outline'
import { formatDate } from '~/helpers/time'

const { getBlogPosts } = useLexas()
// This gets 3 latest blog posts. The limit is set inside the parentheses
const { data: blogs, error } = await getBlogPosts(3)
if (error.value) {
  console.error('Error while getting blog highlights', error.value)
}
</script>
