<template>
  <div class="no-crumbs">
    <h1 class="sr-only">
      Tarvike.com
    </h1>
    <section class="grid pb-4 md:pt-4 gap-4">
      <LexasHeroBanner class="md:container flex items-center justify-center" />
      <LexasTileBanner class="container" />
    </section>
    <section class="my-8">
      <ProductTileCarousel
        product-search-query="categories.id:3729"
        carousel-title="Tarjoukset"
        search-key="homePageOffers"
        :hits-per-page="12"
      >
        <HeadingLink to="/category/tarjoukset" button-text="Kaikki tarjoukset">
          <h2 class="beta">
            Tarjoukset
          </h2>
        </HeadingLink>
      </ProductTileCarousel>
      <ProductTileCarousel
        product-search-query="categories.id:3728"
        carousel-title="Uutuudet"
        search-key="homePageNewArrivals"
        :hits-per-page="12"
      />
    </section>
    <BlogHighlights />
  </div>
</template>
